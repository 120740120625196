<template>
  <v-container>
    <v-row justify="center" class="headline primary--text">
      Parabéns!
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-img
          :src="require('@/assets/pit_feliz.png')"
          height="250px"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="grey--text text--darken-2 title font-weight-regular"
    >
      <v-col lg="6" sm="12" class="text-center">
        Um e-mail de confirmação foi enviado para você, confira sua caixa de
        entrada e confirme seu cadastro.
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="6">
        <v-btn block outlined color="primary" @click="$root.$emit('close_cad')">
          ESTOU CIENTE, VOLTAR PARA A TELA INICIAL
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
